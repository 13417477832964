//
// Design based on https://demos.creative-tim.com/argon-design-system-react/.
//

.appContainer {
  text-align: center;
  color: #fff;
  min-height: 100vh;
  position: relative;
  z-index: 20;
}

.appBackground {
  z-index: 10;
  position: fixed;
  width: 100%;
  height: 100%;
}

.appContent {
  position: relative;
  top: 0px;
  left: 0px;
  z-index: 30;
  min-height: 100%;
}

// Pass-through for particles.
.mousePassThrough {
  pointer-events: none;
}

.mousePassThroughDisabled {
  pointer-events: auto;
}

.logo {
  max-width: 200px;
  max-height: 200px;
  pointer-events: none;
  user-select: none;
}

.lead {
  font-size: 1.25rem;
  margin-top: 1.5rem;
  font-family: "Open Sans",sans-serif;
  line-height: 1.7;
}

.btnPhone {
  padding: 14px 16px !important;
  font-size: 14px !important;
  font-family: "Open Sans",sans-serif;
  font-weight: 600 !important;
  color: #212529 !important;
  background-color: #fff !important;
  border-color: #fff !important;
  box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%) !important;
}

.btnPhone:hover {
  color: #212529 !important;
  background-color: #e6e6e6 !important;
  border-color: #dfdfdf !important;
  box-shadow: 0 7px 14px rgb(50 50 93 / 10%), 0 3px 6px rgb(0 0 0 / 8%) !important;
  transform: translateY(-1px) !important;
}

.textWarning {
  color: #fb6340 !important;
}

.btnPhoneIcon {
  font-size: 19.2px;
  position: relative;
  top: 2px;
}

.btnMail {
  padding: 14px 16px !important;
  font-size: 14px !important;
  font-family: "Open Sans",sans-serif;
  font-weight: 600 !important;
  color: #fff !important;
  background-color: #222 !important;
  border-color: #222 !important;
  box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%) !important;
}

.btnMail:hover {
  box-shadow: none !important;
  transform: translateY(-1px) !important;
}

.btnMail:active {
  color: #fff !important;
  background-color: #090909 !important;
  border-color: #222 !important;
}

.btnMailIcon {
  font-size: 19.2px;
  position: relative;
  top: 2px;
}

.heroContainer {
  padding-bottom: 4rem !important;
  padding-top: 4rem !important;
}

%absoluteWrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.backgroundWrapper {
  //@extend %absoluteWrapper;
  display: none;
  z-index: 10;
}

.contentWrapper {
  //@extend %absoluteWrapper;
  z-index: 20;
}
