html {
  overflow: hidden;
  //width: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: rgb(60,39,143);
  background: linear-gradient(150deg, rgba(60,39,143,1) 15%, rgba(49,24,103,1) 70%, rgba(37,16,77,1) 94%) fixed;
  // Prevent Address-Bar hiding in mobile Browsers - https://stackoverflow.com/a/33953987/2582462
  width: 100%;
  height: 100%;
  position: fixed;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
